import React from "react";
import "./styles.css"; // Certifique-se de criar esse arquivo para estilização
import Img1 from "../../img/img1.jpg";

const HomePage = () => {
  return (
    <div className="container">


      <div className="image-container">
      <img src={Img1} alt="AL Materiais de Construção" />
      </div>
      <br></br>
      <h1>Contato</h1>
      <form className="form">
        <div className="form-group">
          <label htmlFor="name">Nome:</label>
          <input type="text" id="name" name="name" placeholder="Seu nome" required />
        </div>

        <div className="form-group">
          <label htmlFor="phone">Número de Celular:</label>
          <input type="tel" id="phone" name="phone" placeholder="Seu número de celular" required />
        </div>

        <div className="form-group">
          <label htmlFor="subject">Assunto:</label>
          <input type="text" id="subject" name="subject" placeholder="Assunto" required />
        </div>

        <button type="submit" className="submit-button">Enviar</button>
      </form>
    </div>
  );
};

export default HomePage;
